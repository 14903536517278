.bgImage {
    background-image: url("./img/Splash.jpeg");
}

.bgImageInner {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 67% 100%);
}

* {
    font-family: 'Avenir', sans-serif;
}

#qR section div{
    border: 0px solid #fff !important;
    border-radius: 16px;
    box-shadow: rgb(255 0 0 / 50%) 0px 0px 0px 0px inset !important;
}
#qR section{
    border-radius: 16px;
}